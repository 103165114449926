@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Color+Emoji&display=swap');

html,
body {
  margin: 0;
}
.MuiCardActionArea-root {
  font-family: 'Roboto';
}

.privacy_policy_____content > p {
  color: #676767;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 28px;
}
.filtered-img {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
}

.noto-color-emoji {
  font-family: 'Noto Color Emoji', sans-serif;
  font-weight: 400;
  font-style: normal;
}
